export const config = {
  API_URL: `https://backend.dev.supercrm.ai/v1`,
  // API_URL:'http://localhost:4000/v1',
  URL: `st-beta-storage.s3.ap-south-1.amazonaws.com`,
  ASSETS_CDN_ENDPOINT: "st-beta-storage.s3.ap-south-1.amazonaws.com",
  MAIN_URL: `https://dev.superteamonline.com/`,
  PUSHER_APP_KEY: `1f9cc7591e9bc9385a64`,
  PUSHER_APP_CLUSTER: `ap2`,
  REACT_APP_GOOGLE_MAPS_API_KEY: `AIzaSyCvzAcBeETi8eSSUzUuK6HjziyWAAE3-Mo`,
};
